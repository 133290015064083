import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PersonalServiceSidebar from "../components/Repeating/PersonalServiceSidebar";
import PersonalServiceSlider from "../components/Repeating/PersonalServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Family-Owned Business CPA | DC, MD & VA | DeBlanc"
        description="Get help navigating the challenges of your family-owned business. At DeBlanc + Murphy, your DC CPA firm, we have decades of experience and give targeted advice."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Family-Owned Business Services in Washington DC</h1>
            <p className="mb-0 md:text-xl">
              There’s a unique set of challenges that comes with running a
              family business. At DeBlanc, Murphy & Murphy, we know how to navigate
              them. Serving the greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Your Washington, DC Business Services CPAs</h2>
                <p>
                A family business can be very different from other businesses. Its purpose, goals, and operations are unique to the family. And a family business will face unique challenges that traditional businesses do not. Planning for these challenges can ensure the long-term sustainability of both the business and the family.
                </p>
                <p>Some family businesses and families become so intertwined that they require the formation of a family office. A family office provides formalized support services to the business and family members. A family office has three main functions: </p>

                <ul className="styled-list-plus alt mb-6">
                <li>To formally manage the family’s wealth that lies outside the operating business (including developing an investment strategy, investment reporting, and cash management)</li>
                <li>To provide formalized support services to family members, including tax preparation, financial, estate and insurance planning, accounting and bill pay, advising on and administering philanthropic endeavors, managing outside service providers, and managing non-portfolio assets such as real estate, boats, planes, and cars</li>
                <li>To establish and maintain governance structures and processes that ensure both the business and family continue to thrive for generations to come</li>
                </ul>
              </div>

              <div>
                
                <p>
                At DeBlanc, Murphy, and Murphy, we can help you:
                </p>

                <ul className="styled-list-plus alt mb-6">
                  <li>Set up, build out, and manage your family office</li>
                  <li>Provide accounting, planning, and reporting</li>
                  <li>Develop intentionally structured governance that enables you to make great decisions for both your business and your family</li>
                  <li>Develop an Owner Strategy Statement so you can define your success for the business and the family</li>
                  <li>Draft a Family Business Communication Plan that enables the flow of communication between the business and the family</li>
                  <li>Develop a Family Employment Policy that enables family members to participate in the business while maintaining family boundaries</li>
                  <li>Build and manage an investment portfolio to create a legacy that lasts for generations</li>
                  <li>Structure the purchase and management of the family’s non-portfolio assets</li>
                  <li>Prepare and manage your succession and estate plan</li>
                  <li>Provide support services to family members, including accounting, bill pay, tax preparation, educating family members on business and financial matters, assisting with family meetings, and more
                      </li>
                </ul>
                <p>Wealth management services are offered through DeBlanc Wealth Management, LLC., a Registered Investment Advisor, and sister-company of DeBlanc, Murphy, and Murphy, LLC.</p>

                <ButtonSolid modal="modal-contact" altStyle={2} text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <PersonalServiceSidebar activeService={3} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <PersonalServiceSlider hideService={3} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "3.4 Family Business/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
